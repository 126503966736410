import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ServiceHero from '../components/service-hero'
import CTA from '../components/cta-bar'
import PhotoGalleryComponent from '../components/photo-gallery-component'

import styled from 'styled-components'
import Card from '../components/card'
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'

import { TestimonialSection } from '../components/testimonial_video'

import { getSchemaSameAs } from '../helpers/schema-json'
import { intersperse } from '../helpers/react'

const MainContentContainer = styled.div``

const WhiteBGSection = styled.section`
  background: #f9f9f9;
  padding: 2.5em 1em;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 4px 0px;
`

const SidebarContainer = styled.div``

class ServiceCityTemplate extends Component {
  render() {
    let { pageContext } = this.props
    const city = pageContext.city
    const stateAbbreviation = pageContext.stateAbbreviation || ''

    function parseContent(string, altCity) {
      if (string) {
        const newString = string.replace(/{{city}}/g, altCity ? altCity : city)
        const secondReplacement = newString.replace(
          /{{citySlug}}/g,
          (altCity ? altCity : city)
            .replace(/\./g, '')
            .toLowerCase()
            .replace(/\s/g, '-')
            .replace(/\'/g, '')
        )
        const stateAbbreviatedContent = secondReplacement.replace(
          /{{stateAbbreviation}}/g,
          stateAbbreviation.replace(/\./g, '')
        )
        const stateAbbreviatedLink = stateAbbreviatedContent.replace(
          /{{stateAbbreviationLink}}/g,
          stateAbbreviation.replace(/\./g, '').toLowerCase()
        )
        return stateAbbreviatedLink
      }
    }

    let pageData = this.props.data.contentfulServiceAreaContent
    let citiesList = this.props.data.allContentfulServiceArea.edges
    let content = parseContent(pageData.content.content)
    let tidbits = ''
    tidbits = pageContext.tidbits
    let zipCodes = pageContext.zipCodes


    const TopBar = () => {
      //pageData has the data
      const topBar = pageData.topbar || []
      return topBar.map(({ id, title, cssClasses, content }) => (
        <div className="topbarcity">
          <section
            className={cssClasses}
            dangerouslySetInnerHTML={{ __html: content.content }}
          />
        </div>
      ))
    }

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => {
        if (sidebar.__typename == 'ContentfulSidebar') {
          return <Card key={index} sidebar={sidebar} />
        } else if (sidebar.__typename == 'ContentfulSidebarCollection') {
          const innerCards = sidebar.sidebars.map((sidebar, index) => {
            return <Card key={'inner' + index} sidebar={sidebar} />
          })
          return innerCards
        } else return null
      })

      return sideBarCards
    }

    const { testimonials: testimonial_video_posts } =
      pageData.testimonialSection || { testimonials: [] }

    const schemaSameAs = getSchemaSameAs(
      this.props.data.site.siteMetadata.social
    )

    return (
      <>
        <Layout pageProps={this.props} customSchema servicePage={true}>
          <SEO
            title={parseContent(pageData.seoTitle)}
            description={parseContent(pageData.metaDescription)}
            img={pageData.heroImage.gatsbyImageData.images.fallback.src}


          />
          <ServiceHero
            pageData={pageData}
            serviceTitle={parseContent(pageData.serviceTitle)}
            heroImage={pageData.heroImage}
            excerpt={parseContent(pageData.excerpt)}
            pageProps={this.props}
            specialOffer={parseContent(pageData.specialOffer)}
            offerDisclaimer={parseContent(pageData.offerDisclaimer)}
            badges={pageData.headerFeaturedBadges}
          />
          {pageData.socialReviewsFullWidth &&
          pageData.socialReviewsFullWidth.slug ? (
            <SocialReviewsFullwidth
              modalLinkTo={`/reviews/${pageData.socialReviewsFullWidth.slug}/`}
            />
          ) : null}
          {pageData &&
          pageData.photoGallery &&
          pageData.photoGallery[0].photos ? (
            <WhiteBGSection className="photogallerysec">
              <PhotoGalleryComponent gallery={pageData.photoGallery} />
            </WhiteBGSection>
          ) : null}
          <TopBar />
          <TestimonialSection
            testimonial_video_posts={testimonial_video_posts}
          />

          <div className="container brand-padding-y pt-0">
            <div className="row">
              <MainContentContainer
                className={
                  pageData.sidebar
                    ? 'col-12 col-md-8 is-service-page'
                    : 'col-12 is-service-page'
                }
              >
                {' '}
                <div dangerouslySetInnerHTML={{ __html: content }} />
                {zipCodes ? (
                  <h3 class="servicedzip">
                    Serviced ZIP Codes:{' '}
                    {intersperse(
                      zipCodes.map((zipCode, i) => zipCode),
                      ', '
                    )}
                    {zipCodes.map((zipCode) => (
                      <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                          __html: `
                            {
                              "@context": "https://www.schema.org",
                              "@type": "PostalAddress",
                              "addressLocality": "${this.props.pageContext.city}",
                              "addressRegion": "${this.props.pageContext.state}",
                              "postalCode": "${zipCode}"
                            }
                           `,
                        }}
                      />
                    ))}
                  </h3>
                ) : null}
                <div className="pt-4 pb-5">
                  <h3>Areas Served</h3>
                  <ul className="flex flex-wrap list-none m-0">
                    {citiesList.map((item, i) => (
                      <li key={i} className="w-1/2 md:w-1/3 flex">
                        <div class="flex-shrink-0 pr-1">
                          <svg
                            class="h-6 w-6 text-green-500"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>{' '}
                        <Link
                          className="text-gray-800"
                          title={`${parseContent(
                            pageData.serviceTitle,
                            item.node.location
                          )}`}
                          to={`/${pageData.slug}-${item.node.slug}/`}
                        >
                          {item.node.location}, {item.node.stateAbbreviation}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </MainContentContainer>

              {pageData.sidebar ? (
                <SidebarContainer className="col-12 col-md-4">
                  <div className="aspect-ratio-box mb-4 rounded-lg shadow-md">
                    <iframe
                      className="aspect-ratio-box-inside projects-project"
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCwXnoLHPbHNOjKkwCoFY8u4RHtqDGp8dM&q=${this.props.pageContext.city}+${this.props.pageContext.stateAbbreviation}`}
                      width="100%"
                      frameBorder={0}
                      height="400"
                      loading="lazy"
                    />
                  </div>
                  <SidebarColumn />
                </SidebarContainer>
              ) : (
                ''
              )}
            </div>
          </div>

          <hr />
          <div className="container mt-4 mb-4">
            <h4>
              About {city}, {stateAbbreviation}
            </h4>
            <div
              className="service-a-contents tidbitssec"
              dangerouslySetInnerHTML={{ __html: tidbits }}
            />
          </div>
          <CTA
            title={
              'Start Your ' +
              parseContent(pageData.serviceTitle) +
              ' Project Today!'
            }
            customTitle={parseContent(pageData.footerCallToActionHeading)}
            customDesc={parseContent(pageData.footerCallToActionDesc)}
            bgimage={pageData.footerCallToActionImg}
            serviceHeroImage={pageData.heroImage}
            btn={'Get Started!'}
            props={this.props}
          />
        </Layout>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
              {
                "@id": "https://www.usqualityofcolumbus.com#HomeAndConstructionBusiness",
                "@context": "https://www.schema.org",
                "@type": "HomeAndConstructionBusiness",
                "name": "US Quality Construction of Columbus",
                "url": "https://www.usqualityofcolumbus.com/",
                "logo": "https://images.ctfassets.net/ml5xp0ucm4ah/573COoVFKGRFBMAl0O6PQc/ec745cb7ce4b29ecac22961387dd3e62/us-quality-logo-columbus.svg",
                "image": "https://images.ctfassets.net/ml5xp0ucm4ah/7APjtPU95L7UPyQM2sW1IG/49a93bc15f3ce1d9d0b5a2260ffbdd42/wood-siding2.jpeg",
                "description": "US Quality Construction is a Local Siding Contractor Near You Specializing in Siding Replacement, Siding Installation, Vinyl Siding, Decks, Replacement Windows & More.",
                "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "${this.props.pageContext.city}",
                    "addressRegion": "${this.props.pageContext.stateAbbreviation}",
                    "postalCode": "${this.props.pageContext.zipCode}"
                },
                
                "hasMap": "",
                "openingHours": "Mo, Tu, We, Th, Fr 10:00-17:00",
                "telephone": "+1 614-364-4543",
                "priceRange":"$$",  
                "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "sales",
                    "telephone": "+1 614-364-4543"
                }
            }
            `,
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context": "http://schema.org/",
              "@type": "service",
              "servicetype": "${pageData.serviceTitle.replace('{{city}}', '')}",
              "provider": {
                  "@type": "organization",
                  "name": "${this.props.data.site.siteMetadata.title}",
                  "url": "${
                    this.props.data.site.siteMetadata.siteUrl +
                    this.props.location.pathname
                  }",
                  "logo": "https://images.ctfassets.net/ml5xp0ucm4ah/k3dicBOhCSc3einkPzkid/14888f70c121b896f531c5df86949557/usqc-thumb.jpg",
                  "image": "https://images.ctfassets.net/ml5xp0ucm4ah/7APjtPU95L7UPyQM2sW1IG/49a93bc15f3ce1d9d0b5a2260ffbdd42/wood-siding2.jpeg",
                  "address": {
                      "@type": "postaladdress",
                      "addressLocality": "${this.props.pageContext.city}",
                      "addressRegion": "${
                        this.props.pageContext.stateAbbreviation
                      }"
                  },
                  "contactpoint": {
                      "@type": "contactpoint",
                      "contacttype": "customer support",
                      "telephone": "${
                        this.props.data.site.siteMetadata.companyInfo.phone
                      }",
                      "email": "${
                        this.props.data.site.siteMetadata.companyInfo.email
                      }"
                  },
                  "sameas": [${schemaSameAs.map((link) => `"${link}"`)}]
              },
              "areaserved": [{
                  "@type": "city",
                  "name": "${this.props.pageContext.city}",
                  "@id": "https://en.wikipedia.org/wiki/${this.props.pageContext.city.replace(
                    ' ',
                    '_'
                  )},_${this.props.pageContext.state.replace(' ', '_')}"
              }],
              "hasoffercatalog": {
                  "@type": "offercatalog",
                  "name": "${pageData.serviceTitle.replace('{{city}}', '')}",
                  "itemlistelement": [{
                      "@type": "offercatalog",
                      "name": "${pageData.serviceTitle
                        .replace('{{city}}', '')
                        .toLowerCase()} services",
                      "itemlistelement": [
                        ${(pageData.schemaItemOffered || []).map(
                          (item) =>
                            `{
                        "@type": "offer",
                        "itemoffered": {
                            "@type": "service",
                            "name": "${item
                              .replace(/{{city}}/g, this.props.pageContext.city)
                              .replace(
                                /{{state}}/g,
                                this.props.pageContext.state
                              )}"
                        }
                    }`
                        )}]
                  }]
              }
          }
        `,
          }}
        />
      </>
    )
  }
}

export default ServiceCityTemplate

export const pageQuery = graphql`
  query serviceCityQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        companyInfo {
          phone
          email
        }
        social {
          guildquality
          facebook
          yelp
          instagram
          youtube
          google
          # angies
          # linkedin
          # bbb
        }
      }
    }
    contentfulServiceAreaContent(slug: { eq: $slug }) {
      id
      serviceTitle
      seoTitle
      metaDescription
      slug
      excerpt
      specialOffer
      offerDisclaimer
      socialReviewsFullWidth {
        slug
      }
      heroImage {
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
      content {
        content
      }
      photoGallery {
        id
        title
        description {
          description
        }
        photos {
          id
          title
          fixed(width: 300, height: 250) {
            width
            height
            src
            srcSet
          }
          fluid(maxWidth: 1600) {
            src
          }
        }
      }
      topbar {
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
      headerFeaturedBadges {
        title
        gatsbyImageData(width: 300)
      }
      footerCallToActionHeading
      footerCallToActionDesc
      footerCallToActionImg {
        gatsbyImageData(width: 1800)
      }
      testimonialSection {
        testimonials {
          id
          title
          projectReference {
            slug
          }
          video {
            id
            title
            file {
              url
              contentType
            }
          }
          thumbnail {
            gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
          }
        }
      }
      sidebar {
        __typename
        ... on ContentfulSidebar {
          id
          title
          content {
            id
            content
          }
          cssClasses
        }
        ... on ContentfulSidebarCollection {
          sidebars {
            id
            title
            content {
              id
              content
            }
            cssClasses
          }
        }
      }
      schemaItemOffered
    }
    allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          location
          state
          stateAbbreviation
          slug
          zipCodes
        }
      }
    }
  }
`
